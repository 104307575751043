import { BASE, USER } from '../constants';
import { useRoleQuery } from '../services/setting';
import { PIIRole, Role } from '../types/setting';
import flagon, { Flagon } from '../utils/flagon';
import useAppSelector from './use-app-selector';

export const flagRole = (role?: Role) => {
  return flagon(role?.value || 0);
};

export const prepareRoleHookReturns = (
  data?: PIIRole,
  accessToken?: string
): [Flagon, Role] => {
  if (!data) {
    // logged in but /role is not working
    // set as USER
    if (accessToken) {
      return [flagon(USER.value), USER];
    }

    return [flagon(BASE.value), BASE];
  }

  return [flagRole(data.role), data.role];
};

const useRole = (): [Flagon, Role] => {
  const username = useAppSelector((state) => state.auth.username);
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const { data } = useRoleQuery(btoa(username), { skip: !username });

  return prepareRoleHookReturns(data, accessToken);
};

export default useRole;
