import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { SMARTPAY_DOCUMENT_URL } from '../../constants';
import useAppDispatch from '../../hooks/use-app-dispatch';
import useAppSelector from '../../hooks/use-app-selector';
import { getApiKeys } from '../../redux/setting-api-keys';
import APIKeyInput from './APIKeyInput';
import styles from './CredentialsScreen.module.scss';
import SettingNavBar from './SettingNavBar';

const CredentialsScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation');
  const livePublicKey = useAppSelector(
    (state) => state.settingApiKeys.livePublicKey
  );
  const livePrivateKey = useAppSelector(
    (state) => state.settingApiKeys.livePrivateKey
  );
  const testPublicKey = useAppSelector(
    (state) => state.settingApiKeys.testPublicKey
  );
  const testPrivateKey = useAppSelector(
    (state) => state.settingApiKeys.testPrivateKey
  );

  useEffect(() => {
    dispatch(getApiKeys());
  }, [dispatch]);

  return (
    <Layout title={t('settings.title')} className={styles.layout}>
      <SettingNavBar />
      <div className={styles.container}>
        <section>
          <h2>{t('settings.credentials.test-api-key')}</h2>
          <APIKeyInput
            label={t('settings.credentials.public-key')}
            name="test_public_key"
            itemName="Test Public Key"
            value={testPublicKey}
          />
          <APIKeyInput
            label={t('settings.credentials.secret-key')}
            name="test_secret_key"
            itemName="Test Secret Key"
            value={testPrivateKey}
            masked
            maskable
          />
        </section>
        {livePublicKey && livePrivateKey && (
          <section>
            <h2>{t('settings.credentials.live-api-key')}</h2>
            <APIKeyInput
              label={t('settings.credentials.public-key')}
              name="live_public_key"
              itemName="Live Public Key"
              value={livePublicKey}
            />
            <APIKeyInput
              label={t('settings.credentials.secret-key')}
              name="live_secret_key"
              itemName="Live Secret Key"
              value={livePrivateKey}
              masked
              maskable
            />
          </section>
        )}
      </div>
      <p className={styles.contact}>
        <Trans
          i18nKey="settings.credentials.desc"
          components={{
            documentLink: (
              <a
                href={SMARTPAY_DOCUMENT_URL}
                onClick={() => {
                  Mixpanel.trackAction({
                    action: 'Click',
                    itemName: 'Documentation',
                  });
                }}
              >
                Learn more
              </a>
            ),
          }}
        />
      </p>
    </Layout>
  );
};

export default CredentialsScreen;
