import {
  AccountInfo,
  KeySet,
  BankAccount,
  FeePlan,
  PayoutSchedule,
  PIIRole,
} from '../types/setting';
import baseAPI, { middlewareAPI } from './base';

export const settingAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    accountInfo: builder.query<AccountInfo, void>({
      query: () => ({
        url: `/me`,
      }),
    }),
    apiKeys: builder.query<KeySet, void>({
      query: () => ({
        url: `/me/api-keys`,
      }),
    }),
    bankAccount: builder.query<BankAccount | undefined, void>({
      query: () => ({
        url: `/me/bank-accounts`,
      }),
      transformResponse: (data: BankAccount[]) =>
        data.find((e: BankAccount) => e.status === 'active'),
    }),
    feePlan: builder.query<FeePlan | undefined, void>({
      query: () => ({
        url: `/me/fee-plans`,
      }),
      transformResponse: (data: FeePlan[]) =>
        data.find((e: FeePlan) => e.active),
    }),
    payoutSchedule: builder.query<PayoutSchedule | undefined, void>({
      query: () => ({
        url: `/me/payout-schedules/current`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const permissionAPI = middlewareAPI.injectEndpoints({
  endpoints: (builder) => ({
    role: builder.query<PIIRole, string>({
      query: (token: string) => ({
        url: `/me/role/${token}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAccountInfoQuery,
  useLazyAccountInfoQuery,
  useFeePlanQuery,
  useBankAccountQuery,
  usePayoutScheduleQuery,
} = settingAPI;
export const { useRoleQuery, useLazyRoleQuery } = permissionAPI;
