import { Link, LinkProps, useLocation } from '@reach/router';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import IconInfo from '../assets/icon-info.svg';
import IconCouponActive from '../assets/icon-sidebar-coupons-active.svg';
import IconCoupon from '../assets/icon-sidebar-coupons.svg';
import IconOrderActive from '../assets/icon-sidebar-order-active.svg';
import IconOrder from '../assets/icon-sidebar-order.svg';
import IconPaymentLinkActive from '../assets/icon-sidebar-payment-link-active.svg';
import IconPaymentLink from '../assets/icon-sidebar-payment-link.svg';
import IconPayoutActive from '../assets/icon-sidebar-payout-active.svg';
import IconPayout from '../assets/icon-sidebar-payout.svg';
import IconProfileActive from '../assets/icon-sidebar-profile-active.svg';
import IconProfile from '../assets/icon-sidebar-profile.svg';
import logo from '../assets/logotype-white.svg';
import { useDataMode } from '../utils/helper';
import styles from './Layout.module.scss';
import Loading from './Loading';
import ProfilePopup, { PROFILE_POPUP_SCREEN } from './Popup/ProfilePopup';
import DataToggle from './Toggle/DataToggle';
import { PERMISSION } from '../constants';
import useRole from '../hooks/use-role';

const SideBarItem = ({
  id,
  src,
  title,
}: {
  id: string;
  src: string;
  title: string;
}) => (
  <>
    <img src={src} width={24} height={24} alt="" />
    <span id={id}>{title}</span>
  </>
);

const SideBarLink = (
  props: React.PropsWithoutRef<LinkProps<{}>> &
    React.RefAttributes<HTMLAnchorElement> & {
      id: string;
      src: string;
      title: string;
      active_src: string;
    }
) => {
  const { id, src, active_src, title } = props;

  return (
    <Link
      {...props}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        const isActive = isCurrent || isPartiallyCurrent;

        return {
          className: isActive ? styles.active : null,
          children: (
            <SideBarItem
              id={id}
              src={isActive ? active_src : src}
              title={title}
            />
          ),
        };
      }}
    />
  );
};

const Strip = ({ title }: { title: string }) => {
  return (
    <div className={styles['strip-container']}>
      <div className={styles.strip}>
        <span>
          <img src={IconInfo} width={16} height={16} alt="" />
          {title}
        </span>
      </div>
    </div>
  );
};

const Layout = ({
  className,
  mainClassName,
  children,
  title = '',
  isLoading = false,
}: {
  className?: string;
  mainClassName?: string;
  children: ReactNode;
  title?: string;
  isLoading?: boolean;
}) => {
  const { t, i18n } = useTranslation('translation');
  const [role] = useRole();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const { href } = useLocation();
  const isSettings = isShowPopup || href.includes('settings/');
  const { test: isTestDataMode } = useDataMode();
  const currentLang = i18n?.language || 'ja';

  return (
    <div className={cx(styles.root, className)}>
      <aside>
        <Link to="/">
          <img src={logo} height={24} alt="Smartpay" />
        </Link>
        <nav>
          <section className={styles.top}>
            {role.contains(PERMISSION.ORDERS) && (
              <SideBarLink
                id="menu_orders"
                to="/orders"
                src={IconOrder}
                active_src={IconOrderActive}
                title={t('sidebar-item.orders')}
              />
            )}
            {role.contains(PERMISSION.PAYOUTS) && (
              <SideBarLink
                id="menu_payouts"
                to="/payouts"
                src={IconPayout}
                active_src={IconPayoutActive}
                title={t('sidebar-item.payouts')}
              />
            )}
            {role.contains(PERMISSION.COUPONS) && (
              <SideBarLink
                id="menu_coupons"
                to="/coupons"
                src={IconCoupon}
                active_src={IconCouponActive}
                title={t('sidebar-item.coupons')}
              />
            )}
            {role.contains(PERMISSION.PAYMENT_LINK) && (
              <SideBarLink
                id="menu_payment_links"
                to="/payment-links"
                src={IconPaymentLink}
                active_src={IconPaymentLinkActive}
                title={t('sidebar-item.payment-links')}
              />
            )}
          </section>
          <section className={styles.bot}>
            <div className={styles['switch-wrapper']}>
              <button
                className={styles['lang-switcher']}
                type="button"
                onClick={() =>
                  i18n.changeLanguage(
                    currentLang.startsWith('ja') ? 'en' : 'ja'
                  )
                }
              >
                {currentLang.startsWith('ja') ? 'English' : '日本語'}
              </button>
            </div>
            <div>
              <button
                id="btn_profile"
                type="button"
                className={cx(
                  styles.profile,
                  isSettings ? styles.active : null
                )}
                onClick={() => setIsShowPopup(!isShowPopup)}
              >
                <SideBarItem
                  id="menu_profile"
                  src={isSettings ? IconProfileActive : IconProfile}
                  title={t('sidebar-item.profile')}
                />
              </button>
              {isShowPopup && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    Mixpanel.trackAction({
                      screen: PROFILE_POPUP_SCREEN,
                      action: 'Click',
                      itemName: 'Dismiss',
                    });

                    if (isShowPopup) {
                      setIsShowPopup(false);
                    }
                  }}
                >
                  <ProfilePopup />
                </OutsideClickHandler>
              )}
            </div>
            <DataToggle />
          </section>
        </nav>
      </aside>
      {isTestDataMode && <Strip title={t('strip-test-mode-desc')} />}
      {isLoading ? (
        <div className={styles['loading-wrapper']}>
          <Loading />
        </div>
      ) : (
        <main className={cx(mainClassName)}>
          {title && <h1>{title}</h1>}
          {children}
        </main>
      )}
    </div>
  );
};

export default Layout;
