import { AppStore } from '..';
import { refreshToken, signOut } from '../redux/auth';
import axiosInstance from './api';

const setup = (store: AppStore) => {
  let token = '';

  const handleChange = () => {
    const newToken = store.getState()?.auth?.accessToken;

    if (newToken != null && newToken !== token) {
      token = newToken;
    }
  };

  store.subscribe(handleChange);

  axiosInstance.interceptors.request.use(
    (config) => {
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers = config.headers || {};
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      // Refresh token if it needed
      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        store.getState()?.misc?.rememberMe
      ) {
        originalRequest._retry = true;

        const resultAction = await store.dispatch(refreshToken(true));

        if (refreshToken.fulfilled.match(resultAction)) {
          return axiosInstance(originalRequest);
        }

        store.dispatch(signOut(true));

        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        store.dispatch(signOut(true));
      }

      throw error;
    }
  );
};

export default setup;
