import { RouteComponentProps, navigate } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logotype.svg';
import Footer from '../../components/Footer/Footer';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import { FORM_STATE_PENDING, FORM_STATE_PROCESSING } from '../../constants';
import useAppDispatch from '../../hooks/use-app-dispatch';
import { resendOtp, resetSigninAttempt, signInWithOtp } from '../../redux/auth';
import {
  ERROR_NOT_AUTHORIZED,
  ERROR_OTP_NOT_MATCH,
  ERROR_RESOURCE_NOT_FOUND,
  ERROR_UNEXPECTED_ERROR,
} from '../../redux/error-codes';
import { MsgMapping } from '../../types';
import createEventHandler from '../../utils/create-event-handler';
import styles from './TwoFAScreen.module.scss';
import useAppSelector from '../../hooks/use-app-selector';
import OtpResendButton from '../../components/Form/OtpResendButton';
import { pinCodeMaskOption } from '../../components/Form/utils';
import { prepareRoleHookReturns } from '../../hooks/use-role';
import { useLazyRoleQuery } from '../../services/setting';

const ERROR_MSG_MAPPING: MsgMapping = {
  [ERROR_RESOURCE_NOT_FOUND]: 'reset-password.error.user-not-exists',
  [ERROR_OTP_NOT_MATCH]: 'login.error.otp-not-match',
};

const TwoFAScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();
  const dispatch = useAppDispatch();

  const { t } = useTranslation('translation');
  const [otpSecret, setOtpSecret] = useState('');
  const [formState, setFormState] = useState(FORM_STATE_PENDING);
  const [errorCode, setErrorCode] = useState('');
  const username = useAppSelector((state) => state.auth.username);
  const signInAttemptId = useAppSelector((state) => state.auth.signInAttemptId);

  const [queryRole] = useLazyRoleQuery();

  const _onSubmit = useCallback(
    async (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formState === FORM_STATE_PROCESSING) {
        return;
      }

      setFormState(FORM_STATE_PROCESSING);

      const resultAction = await dispatch(
        signInWithOtp({
          signInAttemptId,
          otpSecret: otpSecret.replace(/[^0-9]/g, ''),
        })
      );

      if (signInWithOtp.fulfilled.match(resultAction)) {
        const { data } = await queryRole(btoa(username));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [role, { homepage }] = prepareRoleHookReturns(data);

        navigate(homepage);

        dispatch(resetSigninAttempt());
      } else {
        const responseErrorCode = resultAction?.payload?.errorCode;

        switch (responseErrorCode) {
          case ERROR_OTP_NOT_MATCH:
            setFormState(FORM_STATE_PENDING);
            setErrorCode(ERROR_OTP_NOT_MATCH);
            break;
          case ERROR_NOT_AUTHORIZED: // signin session expired
            navigate('/login');
            break;
          default:
            setFormState(FORM_STATE_PENDING);
            setErrorCode(ERROR_UNEXPECTED_ERROR);
        }
      }
    },
    [dispatch, formState, otpSecret, queryRole, signInAttemptId, username]
  );
  const onSubmit = useMemo(() => createEventHandler(_onSubmit), [_onSubmit]);

  const _onResend = useCallback(async () => {
    await dispatch(resendOtp({ signInAttemptId, otpDispatchMethod: 'mail' }));
  }, [dispatch, signInAttemptId]);
  const onResend = useMemo(() => createEventHandler(_onResend), [_onResend]);

  useEffect(() => {
    if (!signInAttemptId) {
      navigate('/login');
    }
  }, [signInAttemptId]);

  return (
    <div className={styles.container}>
      <h1>
        <img src={logo} height={55} alt="Smartpay" />
      </h1>

      <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.content}>
          <p>{`${username} ${t('login.2fa-desc')}`}</p>
        </div>
        <fieldset>
          <TextInput
            name="otp"
            type="text"
            value={otpSecret}
            onChange={(event) => setOtpSecret(event.currentTarget.value)}
            label=""
            aria-label={t('login.otp.label')}
            placeholder={t('login.otp.placeholder')}
            pattern="\d{3}-\d{3}"
            autoComplete="one-time-code"
            maskOption={pinCodeMaskOption}
            errorMessage={
              errorCode && t(ERROR_MSG_MAPPING[errorCode] || errorCode)
            }
          />
        </fieldset>
        <div className={styles['form-actions']}>
          <Button
            type="submit"
            label={t('continue-btn')}
            processing={formState === FORM_STATE_PROCESSING}
            disabled={!(otpSecret.length === 7)}
            onClick={() =>
              Mixpanel.trackAction({
                action: 'Click',
                itemName: 'Submit',
              })
            }
          />
          <OtpResendButton onClick={onResend} />
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default TwoFAScreen;
