import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  rememberMe: true,
  referrer: '',
  successURL: '',
  cancelURL: '',
};

type State = typeof initialState;
type MiscPairs = Partial<State>;

const miscSlice = createSlice({
  name: '@@merchant-dashboard/misc',
  initialState,
  reducers: {
    updateMiscPairs(state, action: PayloadAction<MiscPairs>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateMiscPairs } = miscSlice.actions;
export default miscSlice.reducer;
