import flagon from './utils/flagon';

export const API_BASE = process.env.REACT_APP_MERCHANT_API_URL;
export const MIDDLEWARE_API_BASE =
  process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE || '/api';
export const MERCHANT_API_BASE = `${API_BASE}/merchants`;
export const SMARTPAY_DOCUMENT_URL = 'https://docs.smartpay.co';

// prettier-ignore
export const PERMISSION = {
  BASE:           0,
  ORDERS:         1 << 0, // eslint-disable-line
  PAYOUTS:        1 << 1, // eslint-disable-line
  COUPONS:        1 << 2, // eslint-disable-line
  PAYMENT_LINK:   1 << 3, // eslint-disable-line
};

export const BASE = {
  value: PERMISSION.BASE,
  role: 'BASE',
  homepage: '/login',
};

export const USER = {
  role: 'USER',
  value: flagon(PERMISSION.BASE)
    .merge(PERMISSION.ORDERS)
    .merge(PERMISSION.PAYOUTS)
    .merge(PERMISSION.COUPONS)
    .value(),
  homepage: '/orders',
};

export const FORM_STATE_PENDING = 'FORM_STATE_PENDING';
export const FORM_STATE_PROCESSING = 'FORM_STATE_PROCESSING';
export const FORM_STATE_SUCCESS = 'FORM_STATE_SUCCESS';

export default {
  API_BASE,
  FORM_STATE_PENDING,
  FORM_STATE_PROCESSING,
  FORM_STATE_SUCCESS,
};

export const LOGIN_SCREEN = 'Login';
export const TWOFA_SCREEN = '2FA';
export const RESET_PASSWORD_SCREEN = 'Reset Password';
export const ORDERS_SCREEN = 'Orders';
export const ORDER_DETAIL_SCREEN = 'Order Detail';
export const PAYMENT_LINKS_SCREEN = 'Payment Links';
export const COUPONS_SCREEN = 'Coupons';
export const COUPON_DETAIL_SCREEN = 'Coupon Detail';
export const COUPON_CREATION_SCREEN = 'Coupon Creation Modal';
export const PAYMENT_LINK_CREATION_SCREEN = 'Payment Link Creation Modal';
export const PROMOTION_CODE_CREATION_SCREEN = 'Promotion Code Creation Modal';
export const PROMOTION_CODE_DETAIL_SCREEN = 'Promotion Code Detail';
export const REFUND_MODAL_SCREEN = 'Refund Modal';
export const MANUAL_CAPTURE_MODAL_SCREEN = 'Manual Capture Modal';
export const CANCEL_ORDER_MODAL_SCREEN = 'Cancel Order Modal';
export const PAYOUTS_SCREEN = 'Payouts';
export const PAYOUT_DETAIL_SCREEN = 'Payout Detail';
export const SETTING_ACCOUNTS_SCREEN = 'Settings Accounts';
export const SETTING_CREDENTIALS_SCREEN = 'Settings Credentials';
export const SETTING_PAYOUTS_SCREEN = 'Settings Payments';
export const SETTING_BRANDING_SCREEN = 'Settings Branding';
export const ERROR_SCREEN = 'Error';

export const DEFAULT_PAGE_SIZE = 20;
// down to 15 from 20 as a row can contain multi-line of items
export const DEFAULT_PAGE_SIZE_PAYMENT_LINK = 15;

export const PROMOTION_CODE_DETAIL_PAGE_SIZE = 5;
export const DEBOUNCE_DELAY = 300;
