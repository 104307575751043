import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Switch from 'react-switch';
import { setDataMode } from '../../redux/setting-accounts';
import { useAccountStatus, useDataMode } from '../../utils/helper';
import Popup from '../Popup/Popup';
import styles from './DataToggle.module.scss';

const DataToggle = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const { test: isTestData } = useDataMode();
  const { test: isTestAccount } = useAccountStatus();

  const innerToggle = (
    <div className={styles['inner-toggle']}>
      <span className={isTestAccount || !isTestData ? styles.inactive : ''}>
        {t('data-toggle.test')}
      </span>
      <Switch
        checked={!isTestData}
        onChange={() => {
          dispatch(setDataMode(isTestData ? 'live' : 'test'));
        }}
        offColor="#fd7a64"
        onColor="#7280ff"
        checkedIcon={false}
        uncheckedIcon={false}
        height={21}
        width={38}
        disabled={isTestAccount}
        handleDiameter={15}
        activeBoxShadow=""
      />
      <span className={isTestAccount || isTestData ? styles.inactive : ''}>
        {t('data-toggle.active')}
      </span>
    </div>
  );

  return (
    <div className={styles['toggle-container']}>
      {isTestAccount ? (
        <Popup
          className={styles['popup-trigger']}
          direction="up"
          content={<div className={styles.desc}>{t('data-toggle.popup')}</div>}
          trigger={innerToggle}
        />
      ) : (
        innerToggle
      )}
    </div>
  );
};

export default DataToggle;
