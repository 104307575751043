import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import settingAPI from '../api/setting';
import { signOut } from './auth';

export const STATUS_PENDING = 'STATUS_PENDING';
export const STATUS_PROCESSING = 'STATUS_PROCESSING';
export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_ERROR = 'STATUS_ERROR';

export const ERROR_UNEXPECTED_ERROR = 'ERROR_UNEXPECTED_ERROR';

type KeySet = {
  kind: 'live' | 'test';
  publicKey: string;
  privateKey: string;
};

const initialState = {
  livePublicKey: '',
  livePrivateKey: '',
  testPublicKey: '',
  testPrivateKey: '',
  status: STATUS_PENDING,
  error: '',
};

export const getApiKeys = createAsyncThunk('setting/getApiKeys', async () => {
  const response = await settingAPI.getApiKeys();

  return response.data;
});

const settingSlice = createSlice({
  name: '@@merchant-dashboard/setting-api-keys',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getApiKeys.fulfilled, (state, action) => {
      state.livePublicKey = '';
      state.livePrivateKey = '';
      state.testPublicKey = '';
      state.testPrivateKey = '';
      const keySets = action.payload;

      keySets.forEach((keySet: KeySet) => {
        switch (keySet.kind) {
          case 'live':
            state.livePublicKey = keySet.publicKey;
            state.livePrivateKey = keySet.privateKey;
            break;
          case 'test':
            state.testPublicKey = keySet.publicKey;
            state.testPrivateKey = keySet.privateKey;
            break;
          default:
        }
      });
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.livePublicKey = initialState.livePublicKey;
      state.livePrivateKey = initialState.livePrivateKey;
      state.testPublicKey = initialState.testPublicKey;
      state.testPrivateKey = initialState.testPrivateKey;
      state.status = initialState.status;
      state.error = initialState.error;
    });
  },
});

export default settingSlice.reducer;
